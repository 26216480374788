import React from "react"
import { Link } from "gatsby"

const TermsAndCondition = () => {
  return (
    <section id="terms-and-condition">
      <div className="plain-section-wrapper pt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-12 pt-4 pb-4">
              <h3 className="h3-careers">Terms & Conditions</h3>
              <ul>
                <li>
                  Rates shown apply to eligible SMSF home loans only, up to 60% LVR, loan amount minimum of $50,000 up to max of $2,000,000. Rates are subject to change without notice. Terms, conditions, and eligibility criteria apply.
                </li>
                <li>
                  Comparison rates are based on a $150,000 loan amount over 25 years. WARNING: This comparison rate is true only for the examples given and may not include all fees and charges. Different terms, fees or other loan amounts might result in a different comparison rate.
                </li>
                <li>
                Best rate guarantee applies when you meet Oxygen’s lending criteria & we can’t match or better the competitors interest rate.  Oxygen will issue a $100 Gift card to client. Customer must show proof of rate from alternate lender - rate must be advertised rate from an official SMSF lender, on a like for like basis (ie same LVR, same security type, same features such as offset etc). Proof of rate for existing (refinance) loans can be a lender statement.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TermsAndCondition
