import React from "react"
import { Link } from "gatsby"

const WhenShouldI = () => {
  return (
    <section id="applyloan">
      <div className="plain-section-wrapper pt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-12 pt-4 pb-4">
              <h3 className="h3-careers">
                When should I apply for an SMSF loan?
              </h3>
              <ul>
                <li>
                  We recommend that you apply for the loan at least two
                  weeks before you begin looking for a property.
                </li>
                <li>
                  This process can be expedited if required. However, it is
                  always best to allow additional time to avoid
                  disappointment.
                </li>
                <li>
                  To begin with,{" "}
                  <a href="/contact/" target="_blank">
                    {" "}
                    contact us{" "}
                  </a>
                  to get the application process started.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhenShouldI
