import React from "react"
import { Link } from "gatsby"
import ReactWOW from "react-wow"
import scrollTo from "gatsby-plugin-smoothscroll"

const GetApproval = () => {
  return (
      <section id="support" className="get-approval">
        <div className="teal-section-wrapper pt-5 pb-5">
          <div className="container">
            <div className="row">
              <div className="col-12 pt-4 pb-4">
                <h3 className="h3-careers">How can I get approval?</h3>
                <p>
                  With Oxygen the process of applying for a SMSF loan is
                  straight forward. We are here to help you along the way.
                  Compared to a regular home loan, there are more documents
                  you have to obtain and share with us, which can take some
                  time in preparation.
                </p>
                <p>
                  Reach out via the contact us form to start the application
                </p>
              </div>
            </div>
            <ReactWOW delay=".05s" animation="fadeInUp">
              <div className="row d-flex justify-content-center pb-5">
                <div className="col-12 col-md-5 col-lg-4">
                  <div className="">
                    <a
                      href="/applynow"
                      className="default-btn btn-contact first-time-buyer-color w-100 btn-lg mt-3 mt-md-0"
                    >Contact Us
                    </a>
                  </div>
                </div>
                <div className="col-12 col-md-5 col-lg-4 download-guide-button">
                  <button
                    className="default-btn navy-btn-solid btn-lg mt-2 mt-md-0"
                    onClick={() => scrollTo(".page-smsf", "start")}
                  >
                    Download Guide Now
                  </button>
                </div>
              </div>
            </ReactWOW>
          </div>
        </div>
      </section>
  )
}

export default GetApproval
