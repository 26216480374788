import React from "react"
import { Link } from "gatsby"

const WhatIsSMSF = () => {
  return (
    <section id="borrow">
      <div className="plain-section-wrapper pt-2 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-12 pt-4 pb-4">
              <h3 className="h3-careers">
                What is a Self-Managed Superannuation Fund? (SMSF)
              </h3>
              <ul>
                <li>
                  A SMSF is a special type of trust that people can set up
                  to manage their own superannuation.
                </li>
                <li>
                  Like a normal super fund, your employer contributions
                  still get paid into the fund, and you can make additional
                  contributions as you see fit.
                </li>
                <li>
                  However, unlike a normal super fund, the trustee (either
                  you or your company) has direct control over the assets
                  that your superannuation is invested in.
                </li>
                <li>
                  Many people also use their SMSF to help plan for their
                  retirement and assist with tax planning.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhatIsSMSF
