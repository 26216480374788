import React from "react"
import { Link } from "gatsby"

const QuickFaq = () => {
  return (
    <section id="quickFaq">
        <div className="plain-section-wrapper pt-5 pb-5">
          <div className="container">
            <div className="row">
              <div className="col-12 pt-4 pb-4">
                <h3 className="h3-careers">
                  Quick FAQs - SMSF Lending
                </h3>
                <ul>
                  <li>You can borrow up to <strong>$3,000,000</strong>  dollars for certain scenarios through Oxygen SMSF Loans.</li>
                  <li><strong>Standard SMSF Investment Loans:</strong> Oxygen can lend up to 80% of the property value.</li>
                  <li><strong>Commercial property:</strong> Oxygen can lend up to 80% of the property value for a wide range of commercial property types.</li>
                  <li><strong>Refinance:</strong> Oxygen can refinance your current SMSF Loan & save you money!</li>
                  <li><strong>Offset Accounts:</strong> Oxygen SMSF Loans offer the option of an 100% offset account.</li>
                  <li><strong>Bad credit:</strong> Oxygen can still accept a SMSF Loan even if the SMSF member (guarantor) has had some past credit issues. This is often on a case by case basis.</li>
                  <li><strong>Low doc (no income evidence):</strong> Oxygen does not offer low doc SMSF Loans.</li>
                  <li><strong>Construction finance:</strong> Oxygen does not offer SMSF construction finance.</li>
                </ul>
                <p>Please talk to your broker or fill in our <a href="/contact/" target="_blank">contact us form</a>  to discuss your options.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}

export default QuickFaq
